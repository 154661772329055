  <template>
    <div role="main" class="main">
      <section
        class="common-page with-footer speaker-room-page"
        style="min-height: 560px"
      >
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-5">
              <div class="row">
                <div class="col-12">
                  <div class="border-l text-left">
                    <p v-if="event_info">PARTING</p>
                  </div>
                </div>
                <div class="col-md-7">
                  <div
                    class="common_page_img-box pointer"
                    @click="goNext('upload_photo')"
                  >
                    <img
                      :src="require('@/assets/img/upload-photo.png')"
                      class="img-fluid float-left"
                    />
                    <label class="text-primary"
                      >Upload<br />
                      Your<br />
                      Photos
                    </label>
                  </div>
                  <div
                    class="common_page_img-box pointer"
                    @click="goNext('donation')"  v-if="event_info.donation_url"
                  >
                    <img
                      :src="require('@/assets/img/DonateWithPaypal.png')"
                      class="img-fluid float-left"
                    />
                    <label class="text-primary"
                      >Gift or Donate with<br />
                      <b>PayPal</b>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-7" v-if="event_info!=null">
                    <div class="c-scroll">
                      <div  v-for="(input,k) in imagesArray" :key="k" class="mb-3">
                      <img
                        :src="input.program_url"
                        class="img-fluid c-img-scoll"
                      />
                      </div>
                    </div>
                  </div>
          </div>
        </div>
      </section>
      <footer class="container">
        <div class="row align-items-end">
          <div class="col-md-3">
            <label class="text-primary">Type condolences message and press send</label>
            <div class="form-group">
              <!--<textarea class="form-control" rows="3" placeholder="Please leave a personal mesagge or a memory here" v-model="message"></textarea> -->
              <VueEmoji
                ref="emoji"
                @input="onInput"
                :value="myText"
                height="200"
              />
            </div>
          </div>
          <div class="col-md-2 pl-lg-0">
            <div class="form-group">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="goNext('condolence')"
              >
                Send your condolences
              </button>
            </div>
          </div>

          <div class="col-md-7 border-left footer_right_wrapper">
            <div class="w-50">
              <div
                class="img-box mt-0 form-group pointer"
                @click="goNext('voicemail')" v-if="event_info.tel"
              >
                <img
                  src="../../assets/img/voicemail.jpg"
                  class="img-fluid float-left mr-2"
                  style="height: 98px"
                />
                <label class="text-primary px-2">
                  <b v-if="event_info && event_info.tel">Record a condolence message</b>
                </label>
              </div>
            </div>
            
            <div class="w-50" v-if="event_info && event_info.zoom_meeting_url">
              <div
                class="img-box mt-0 form-group pointer"
                @click="goNext('zoom')"
              >
                <img
                  :src="require('@/assets/img/zoom1.png')"
                  class="img-fluid float-left mr-2"
                  style="height: 98px"
                />
                <label class="text-primary px-2">
                  <b v-if="event_info">Visit our wake</b>
                </label>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <transition name="pop" appear v-if="video_url!='' && video_url!=null && video_url!='null'&& video_url!=undefined">
        <div
          class="modal fade show program-modal"
          v-if="show_video_model"
          id="myModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModal"
          aria-hidden="true"
          @click="closeModal"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeVideoModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div class="row" >
                  <iframe
                 
                  :src="video_url"
                  allow="autoplay; encrypted-media"
                  frameborder="0"
                  allowfullscreen
                  style="
                    height: 332px;
                    width: 100%;
                    padding: 28px 30px;
                    z-index: 9;
                    position: relative;
                    left: 0px;"
                ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </template>
  <script>
  import { getApiManager } from "../../api";
  import { apiBaseUrl, liveBaseUrl } from "../../constants/config";
  import VueEmoji from "emoji-vue";

  export default {
    data() {
      return {
        event_id: 0,
        event_info: null,
        visitor_id: 0,
        message: "",
        myText: "",
        imagesArray:[],
        show_video_model: true,
        video_url:'',
      };
    },
    mounted() {
      this.event_id = JSON.parse(localStorage.getItem("loginInfo"))
        ? JSON.parse(localStorage.getItem("loginInfo")).event_id
        : 0;
      this.visitor_id = JSON.parse(localStorage.getItem("loginInfo"))
        ? JSON.parse(localStorage.getItem("loginInfo")).token
        : 0;
        this.event_id=this.event_id= this.$route.params.e_id;

      getApiManager()
        .post(`${apiBaseUrl}/api/visitor/profile/get-event`, {
          event_id: this.event_id,
        })
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            this.event_info = res.detail;
            if( this.event_info.video_url!=''){
                if (this.event_info.video_url.includes("watch") == true) {
                  if (
                    this.event_info.video_url.split("=")[1] != null &&
                    this.event_info.video_url.split("=")[1] != undefined &&
                    this.event_info.video_url.split("=")[1] != ""
                  ) {
                      this.video_url =
                        "https://www.youtube.com/embed/" +
                        this.event_info.video_url.split("=")[1] +
                        "?autoplay=1";
                  } else {
                      this.video_url = this.event_info.video_url;
                  }
                } else {
                    this.video_url = this.event_info.video_url;
                }
              
                console.log("ooooooo",this.video_url)
            }
            getApiManager()
            .post(`${apiBaseUrl}/api/admin/event/getuploadImagebyEventId`, {
              event_id:  this.event_id,
            })
            .then((response) => {
                this.imagesArray=response.data.detail;
                console.log("&&&&&&&&&&",this.imagesArray)
                if(this.imagesArray && this.imagesArray.length>0){
                for(var i=0;i<this.imagesArray.length;i++){
                  this.imagesArray[i].program_url=`${apiBaseUrl}` + "/" + this.imagesArray[i].program_url
                }
              }
            })
            // this.event_info.program_url =
            //   `${apiBaseUrl}/` + this.event_info.program_url;
            if (res.detail.status == 1) {
  
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push("/visitor/wait/"+this.event_id+"?x=" + randomnumber);
            } else if (res.detail.status == 2) {
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push("/visitor/live/"+this.event_id+"?x=" + randomnumber);
            } else if (res.detail.status == 3) {
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push("/visitor/parting/"+this.event_id+"?x=" + randomnumber);
            } else {
              this.connectSocket();
            }
          } else {
            this.$notify({
              group: "foo",
              type: "warn",
              title: "Error",
              text: "Server Error",
              animation_type: "slide",
            });
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // this.connectSocket();
    },
    methods: {
      closeVideoModal() {
        this.show_video_model = false;
      },
      closeModal() {
        this.show_video_model = false;
      },
      connectSocket() {
        var ref = this;

        this.connection = new WebSocket(liveBaseUrl);
        this.connection.onmessage = function (event) {
          //this.isLoading = false;
          let data = JSON.parse(event.data);
          if (data.type == "change_order") {
            console.log("PARTING");
            ref.receiveMoveLive(data.order);
          } else if (data.type == "user_info") {
            ref.connection.send(
              JSON.stringify({
                type: "my_info",
                event_id: ref.event_id,
              })
            );
          } else if (data.type == "start_count") {
            console.log("VISOTOR");
            ref.receiveMoveLive(data.order);
          }
        };

        this.connection.onclose = (e) => {
          console.log(
            "Socket is closed. Reconnect will be attempted in 1 second."
          );
          setTimeout(() => {
            ref.connectSocket();
          }, 1000);
        };
      },
      receiveMoveLive(order) {
        this.connection.close();
        if (order == 1) {
          if (this.$route.fullPath != "/visitor/wait") {
            let randomnumber = Math.floor(Math.random() * 10000) + 1;
            this.$router.push("/visitor/wait/"+this.event_id+"?x=" + randomnumber);
          }
        } else if (order == 2) {
          if (this.$route.fullPath != "/visitor/live") {
            let randomnumber = Math.floor(Math.random() * 10000) + 1;
            this.$router.push("/visitor/live/"+this.event_id+"?x=" + randomnumber);
          }
        }
      },
      onInput(event) {
        this.message = event.data;
      },
      clearTextarea() {
        this.$refs.emoji.clear();
      },
      goNext(page) {
        if (page == "condolence") {
          if (this.message == "") {
            this.$notify({
              group: "foo",
              type: "warn",
              title: "Waring",
              text: "Please input the message.",
              animation_type: "slide",
            });
            return;
          }
          getApiManager()
            .post(`${apiBaseUrl}/api/visitor/condolence/create`, {
              event_id: this.event_id,
              message: this.message,
              visitor_id: this.visitor_id,
            })
            .then((response) => {
              let res = response.data;
              if (res.status === true) {
                let randomnumber = Math.floor(Math.random() * 10000) + 1;
                this.$router.push("/visitor/condolence/" + res.condolence_id+"/"+this.event_id+"?x=" + randomnumber);
              } else {
                this.$notify({
                  group: "foo",
                  type: "warn",
                  title: "Error",
                  text: "Server Error",
                  animation_type: "slide",
                });
                return;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (page == "upload_photo"){ 
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          this.$router.push("/visitor/" + page+"?x=" + randomnumber);
        }
        else if (page == "shop") {
          if (this.event_info.flowershop_url.startsWith('http://') || this.event_info.flowershop_url.startsWith('https://')) {
            window.open(this.event_info.flowershop_url, "_blank");
          } else {
            window.open('https://' + this.event_info.flowershop_url, "_blank");
          }
        } else if (page == "donation") {
          if (this.event_info.donation_url.startsWith('http://') || this.event_info.donation_url.startsWith('https://')) {
            window.open(this.event_info.donation_url, "_blank");
          } else {
            window.open('https://' + this.event_info.donation_url, "_blank");
          }
        } else if (page == "calendar") {
          window.open(this.event_info.reminder_url, "_blank");
        } else if (page == "zoom") {
          window.open(this.event_info.zoom_meeting_url, "_blank");
        } else if (page=='voicemail') {
          window.open(this.event_info.tel,"_blank");
        } else {
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          this.$router.push("/visitor/page/" + page+"?x=" + randomnumber);
        }
      },
      goLive() {
        let ref = this;
        getApiManager()
          .post(`${apiBaseUrl}/api/visitor/profile/get-event`, {
            event_id: this.event_id,
          })
          .then((response) => {
            let res = response.data;
            if (res.status === true) {
              this.connection.close();
              if (res.detail.status == 1) {
                clearInterval(ref.checkLive);
                let randomnumber = Math.floor(Math.random() * 10000) + 1;
                this.$router.push("/visitor/wait/"+this.event_id+"?x=" + randomnumber);
              } else if (res.detail.status == 2) {
                clearInterval(ref.checkLive);
                let randomnumber = Math.floor(Math.random() * 10000) + 1;
                this.$router.push("/visitor/live/"+this.event_id+"?x=" + randomnumber);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    components: {
      VueEmoji,
    },
    computed: {},
    watch: {},
  };
  </script>
<style scoped>
.c-img-scoll {
  height: 370px;
    object-fit: cover;
}
.c-scroll{
  height: calc(100vh - 400px);
  overflow: auto;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #772803;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #772803;
}
.c-footer-card-img {
  height: 100px;
  width: 100px;
  object-fit: cover;
}
.link-wrap {
  overflow-wrap:anywhere ;
}

</style>